<template>
    <div class="landing-page">
        <div class="landing-header">
            <div class="header-image">
                <img
                    src="@/assets/images/northen-heights-landing.png"
                    alt="Northern Heights"
                />
                <div class="header-shadow"></div>
            </div>
            <div class="header-image-mobile">
                <div class="header-shadow"></div>

                <div id="mobile-ui">
                    <div class="mobile">
                        <div class="header-logos-mobile">
                            <img
                                src="@/assets/images/northen-height-logo.png"
                                class="northen-height-logo-mobile"
                                alt="Northern Heights Logo"
                            />
                            <img
                                src="@/assets/images/modernite-logo.png"
                                class="modernite-logo-mobile"
                                alt="Modernite Logo"
                            />
                        </div>
                        <div class="mobile-text">
                            <p>
                                {{
                                    landingText.header_text.tag_line.slice(
                                        0,
                                        22,
                                    )
                                }}
                            </p>
                            <p>
                                {{
                                    landingText.header_text.tag_line.slice(
                                        22,
                                        38,
                                    )
                                }}
                            </p>
                            <p>
                                {{ landingText.header_text.tag_line.slice(39) }}
                            </p>
                        </div>
                        <div class="mobile-btn">
                            <Button
                                primary
                                name="Click Here"
                                class="click-here"
                                :handleClick="startQuiz"
                            />
                        </div>
                    </div>
                    <div class="contact-us-mobile">
                        <div class="contact-us-info-mobile">
                            <p class="contact-us-para-mobile">
                                {{ landingText.header_text.queries }}
                            </p>
                            <p class="contact-us-number-mobile">
                                {{ landingText.header_text.contact_us }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="header-logos">
                <img
                    src="@/assets/images/northen-height-logo.png"
                    class="northen-height-logo"
                    alt="Northern Heights Logo"
                />
                <img
                    src="@/assets/images/modernite-logo.png"
                    class="modernite-logo"
                    alt="Modernite Logo"
                />
            </div>
            <div class="contact-us">
                <div class="contact-us-info">
                    <p class="contact-us-para">
                        {{ landingText.header_text.queries }}
                    </p>
                    <p class="contact-us-number">
                        {{ landingText.header_text.contact_us }}
                    </p>
                </div>
            </div>
            <div class="event-details-card">
                <div class="event-details-cover">
                    <div class="event-details">
                        <div class="event-name">
                            {{ landingText.header_text.tag_line.slice(0, 22) }}
                        </div>
                        <div class="event-name">
                            {{ landingText.header_text.tag_line.slice(22) }}
                        </div>
                        <Button
                            primary
                            name="Click Here"
                            class="click-here"
                            :handleClick="startQuiz"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div class="home-design">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="69.8"
                height="47.8"
                viewBox="0 0 69.8 47.8"
            >
                <g data-name="Group 1415" transform="translate(-645 -132)">
                    <circle
                        data-name="Ellipse 45"
                        cx="21"
                        cy="21"
                        r="21"
                        transform="translate(645 132)"
                        style="fill: #ffe8da"
                    />
                    <g data-name="Group 1414">
                        <g data-name="Group 1413">
                            <path
                                data-name="Path 2044"
                                d="M126.995 122.334c-.016-2.691-1.36-3.289-2.484-3.317h-.011a5.8 5.8 0 0 0-4.092 1.94c-.3-2.359-2.282-4.868-5.235-6.538a15.354 15.354 0 0 0-15.506.438 15.353 15.353 0 0 0-15.5-.439c-2.946 1.666-4.929 4.167-5.232 6.522a5.788 5.788 0 0 0-4.075-1.924h-.007c-1.125.028-2.469.626-2.485 3.317-.033 5.574 5.814 16.617 9.078 17.4v5.485a.276.276 0 0 0 .276.276h2.358a.275.275 0 0 0 .266-.2l1.469-5.518h27.731l1.469 5.518a.276.276 0 0 0 .266.2h2.36a.276.276 0 0 0 .276-.276v-5.488c3.266-.781 9.111-11.823 9.078-17.4zm-12.088-7.434c3.014 1.7 4.967 4.294 4.995 6.611-2.222 2.616-3.806 7.065-4.192 12.255H99.947v-18.43a14.812 14.812 0 0 1 14.959-.435zM85.6 139.217h-2.685a5.457 5.457 0 0 0 .831-2.975v-.019c0-.646-.02-1.282-.056-1.909h31.984a33.339 33.339 0 0 0-.056 1.91v.009a.065.065 0 0 1 0 .009 5.461 5.461 0 0 0 .83 2.974zM84.439 114.9a14.809 14.809 0 0 1 14.958.436v18.43H83.654c-.386-5.2-1.978-9.662-4.209-12.275.038-2.312 1.988-4.892 4.994-6.591zm-11.52 7.439c.011-1.8.666-2.737 1.945-2.769a5.542 5.542 0 0 1 4.071 2.135.275.275 0 0 0 .059.068c2.506 2.874 4.2 8.269 4.2 14.444-.092 1.352-.392 2.948-1.338 3h-.125c-2.939-.2-8.846-11.485-8.813-16.88zm10.949 22.6H82v-5.171h3.249zm33.5 0h-1.873l-1.377-5.171h3.249zm.267-5.722h-.135c-.933-.068-1.242-1.652-1.333-3 0-9.177 3.738-16.642 8.331-16.647 1.28.033 1.934.964 1.945 2.769.032 5.392-5.87 16.676-8.81 16.88z"
                                transform="translate(583.412 33.493)"
                                style="
                                    stroke: #333;
                                    fill: #333;
                                    stroke-width: 0.3px;
                                "
                            />
                            <path
                                data-name="Path 2045"
                                d="M267.082 102.48a1.08 1.08 0 1 0 1.08 1.08 1.076 1.076 0 0 0-1.08-1.08zm0 1.561a.516.516 0 0 1-.48-.48.48.48 0 1 1 .96 0 .516.516 0 0 1-.48.48z"
                                transform="translate(401.883 51.826)"
                                style="
                                    stroke: #333;
                                    fill: #333;
                                    stroke-width: 0.3px;
                                "
                            />
                            <path
                                data-name="Path 2046"
                                d="M339.322 73.845a1.08 1.08 0 1 0-1.08-1.08 1.16 1.16 0 0 0 1.08 1.08zm0-1.561a.48.48 0 1 1 0 .961.429.429 0 0 1-.42-.48.464.464 0 0 1 .42-.481z"
                                transform="translate(338.163 80.402)"
                                style="
                                    stroke: #333;
                                    fill: #333;
                                    stroke-width: 0.3px;
                                "
                            />
                            <path
                                data-name="Path 2047"
                                d="M416.042 102.48a1.08 1.08 0 1 0 1.08 1.08 1.076 1.076 0 0 0-1.08-1.08zm0 1.561a.517.517 0 0 1-.48-.48.48.48 0 1 1 .48.48z"
                                transform="translate(270.442 53.986)"
                                style="
                                    stroke: #333;
                                    fill: #333;
                                    stroke-width: 0.3px;
                                "
                            />
                            <path
                                data-name="Path 2048"
                                d="M286.682 176.4a1.08 1.08 0 1 0 1.08 1.08 1.076 1.076 0 0 0-1.08-1.08zm0 1.5a.516.516 0 0 1-.48-.48.48.48 0 1 1 .961 0 .516.516 0 0 1-.481.48z"
                                transform="translate(389.724 -14.17)"
                                style="
                                    stroke: #333;
                                    fill: #333;
                                    stroke-width: 0.3px;
                                "
                            />
                            <path
                                data-name="Path 2049"
                                d="M393.642 176.4a1.08 1.08 0 1 0 1.08 1.08 1.076 1.076 0 0 0-1.08-1.08zm0 1.5a.517.517 0 0 1-.48-.48.48.48 0 1 1 .961 0 .516.516 0 0 1-.481.48z"
                                transform="translate(299.745 -14.17)"
                                style="
                                    stroke: #333;
                                    fill: #333;
                                    stroke-width: 0.3px;
                                "
                            />
                            <path
                                data-name="Path 2050"
                                d="M341 245.84a1.08 1.08 0 1 0 1.08 1.08 1.076 1.076 0 0 0-1.08-1.08zm0 1.561a.516.516 0 0 1-.48-.48.48.48 0 1 1 .961 0 .517.517 0 0 1-.481.479z"
                                transform="translate(354.545 -93.754)"
                                style="
                                    stroke: #333;
                                    fill: #333;
                                    stroke-width: 0.3px;
                                "
                            />
                        </g>
                        <path
                            data-name="Line 3"
                            transform="translate(652 179.5)"
                            style="
                                fill: none;
                                stroke-linecap: round;
                                stroke-width: 0.6px;
                                stroke: #333;
                            "
                            d="M0 0h57.186"
                        />
                        <path
                            data-name="Line 421"
                            transform="translate(711 179.5)"
                            style="
                                fill: none;
                                stroke-linecap: round;
                                stroke-width: 0.6px;
                                stroke: #333;
                            "
                            d="M0 0h3.5"
                        />
                    </g>
                </g>
            </svg>
            <p>
                {{ landingText.header_text.complete_home_design.slice(0, 30) }}
            </p>
            <p>{{ landingText.header_text.complete_home_design.slice(30) }}</p>
        </div>

        <div class="img-group">
            <div class="img-div">
                <div class="img-circle">
                    <img
                        src="@/assets/images/trophy-img.png"
                        alt="trophy-img"
                        class="round-images"
                    />
                </div>
                <p class="img-text">
                    {{ landingText.what_to_expect.data[0].desc }}
                </p>
            </div>
            <div class="img-div">
                <div class="img-circle">
                    <img
                        src="@/assets/images/execution-img.png"
                        alt="execution-img"
                        class="round-images"
                    />
                </div>
                <p class="img-text">
                    {{ landingText.what_to_expect.data[1].desc }}
                </p>
            </div>
            <div class="img-div">
                <div class="img-circle">
                    <img
                        src="@/assets/images/3d-img.png"
                        alt="3d-img"
                        class="round-images"
                    />
                </div>
                <p class="img-text">
                    {{ landingText.what_to_expect.data[2].desc }}
                </p>
            </div>
            <div class="img-div">
                <div class="img-circle">
                    <img
                        src="@/assets/images/medal-img.png"
                        alt="medal-img"
                        class="round-images"
                    />
                </div>
                <p class="img-text best-pricing">
                    {{ landingText.what_to_expect.data[3].desc }}
                </p>
                <div class="price-wrap">
                    <p class="best-pricing-mobile">
                        {{
                            landingText.what_to_expect.data[3].desc.slice(0, 4)
                        }}
                    </p>
                    <p class="best-pricing-mobile">
                        {{ landingText.what_to_expect.data[3].desc.slice(4) }}
                    </p>
                </div>
            </div>
        </div>
        <div class="contact-modal"></div>
        <DesignsFrom :designsFrom="landingText.designs_from" />
        <div class="top-brands">
            <p class="top-brand-text">
                {{ landingText.past_projects.vt_title }}
            </p>
            <div class="top-brand-text">
                <Button primary class="nh-button" :handleClick="showVt">
                    <template #icon>
                        <span style="margin: auto">2 BHK</span>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="19.767"
                            height="19.767"
                            class="virtual-tour-icon"
                        >
                            <g
                                id="play-button"
                                transform="translate(-11.85 .15)"
                            >
                                <g
                                    id="Group_178"
                                    data-name="Group 178"
                                    transform="translate(12)"
                                >
                                    <g id="Group_177" data-name="Group 177">
                                        <path
                                            id="Path_559"
                                            data-name="Path 559"
                                            class="cls-1"
                                            d="M9.733 0a9.733 9.733 0 1 0 9.733 9.733A9.744 9.744 0 0 0 9.733 0zm0 18.656a8.922 8.922 0 1 1 8.922-8.922 8.932 8.932 0 0 1-8.922 8.922z"
                                            fill="#fff"
                                            stroke="#fff"
                                            stroke-width="0.3px"
                                        />
                                        <path
                                            id="Path_560"
                                            data-name="Path 560"
                                            class="cls-1"
                                            d="m198.3 153.046-5.678-3.65a.406.406 0 0 0-.625.341v7.3a.405.405 0 0 0 .625.341l5.678-3.65a.405.405 0 0 0 0-.682zm-5.492 3.248v-5.814l4.522 2.907z"
                                            transform="translate(-184.7 -143.653)"
                                            fill="#fff"
                                            stroke="#fff"
                                            stroke-width="0.3px"
                                        />
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </template>
                </Button>

                <Button primary class="nh-button" :handleClick="showVt">
                    <template #icon>
                        <span style="margin: auto">3 BHK</span>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="19.767"
                            height="19.767"
                            class="virtual-tour-icon"
                        >
                            <g
                                id="play-button"
                                transform="translate(-11.85 .15)"
                            >
                                <g
                                    id="Group_178"
                                    data-name="Group 178"
                                    transform="translate(12)"
                                >
                                    <g id="Group_177" data-name="Group 177">
                                        <path
                                            id="Path_559"
                                            data-name="Path 559"
                                            class="cls-1"
                                            d="M9.733 0a9.733 9.733 0 1 0 9.733 9.733A9.744 9.744 0 0 0 9.733 0zm0 18.656a8.922 8.922 0 1 1 8.922-8.922 8.932 8.932 0 0 1-8.922 8.922z"
                                            fill="#fff"
                                            stroke="#fff"
                                            stroke-width="0.3px"
                                        />
                                        <path
                                            id="Path_560"
                                            data-name="Path 560"
                                            class="cls-1"
                                            d="m198.3 153.046-5.678-3.65a.406.406 0 0 0-.625.341v7.3a.405.405 0 0 0 .625.341l5.678-3.65a.405.405 0 0 0 0-.682zm-5.492 3.248v-5.814l4.522 2.907z"
                                            transform="translate(-184.7 -143.653)"
                                            fill="#fff"
                                            stroke="#fff"
                                            stroke-width="0.3px"
                                        />
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </template>
                </Button>
            </div>
        </div>

        <div class="hassle-free">
            <img
                src="@/assets/images/circle-design.png"
                class="circle-design"
            />
            <img
                src="@/assets/images/circle-design-2.png"
                class="circle-design-2"
                v-if="windowWidth < 970"
            />
        </div>

        <PastProjects :pastProjects="landingText.past_projects" />

        <div class="customer-testimonials">
            <TestimonailsCarousel :user="testimonials" />
        </div>

        <Footer />
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import RegisterStoreModule from 'mixins/RegisterStoreModule'
import HomePageModule from 'store/modules/HomePage'
import ContactPageModule from 'store/modules/ContactPage'
import Button from 'componentsv2/Button'
import Footer from 'componentsv2/Footer'
import TestimonailsCarousel from 'componentsv2/TestimonailsCarousel'
import OtherEvents from 'componentsv2/OtherEvents'
import PastProjects from 'componentsv2/PastProjects'
import DesignsFrom from 'componentsv2/DesignsFrom'
export default {
    name: 'NorthernHeights',
    components: {
        Button,
        TestimonailsCarousel,
        PastProjects,
        DesignsFrom,
        Footer,
    },
    mixins: [RegisterStoreModule],
    data() {
        return {
            showModal: false,
            landingText: {
                header_text: {
                    contact_us: '8-805-806-805',
                    queries: 'For Queries Contact Us:',
                    complete_home_design:
                        'Complete Home Design Solutions Starting At ₹ 2.49 Lacs',
                    tag_line:
                        'For The Best Quotes of Northern Heights Apartments',
                },
                what_to_expect: {
                    data: [
                        {
                            desc: 'Award Winning Designs',
                            image: 'images/trophy-img.png',
                        },
                        {
                            desc: 'Hassle Free Execution',
                            image: 'images/execution-img.png',
                        },
                        {
                            desc: '3D Visualization',
                            image: 'images/3d-img.png',
                        },
                        {
                            desc: 'Best Pricing',
                            image: 'images/medal-img.png',
                        },
                    ],
                },
                designs_from: {
                    title: 'Designs From Top Designers',
                    topDesigns: [
                        {
                            image:
                                'https://rift-prod.s3.ap-south-1.amazonaws.com/landing-page/1 (1)@3x.png',
                            price: 110000,
                        },

                        {
                            image:
                                'https://rift-prod.s3.ap-south-1.amazonaws.com/landing-page/29@3x.png',
                            price: 130000,
                        },
                        {
                            image:
                                'https://rift-prod.s3.ap-south-1.amazonaws.com/landing-page/8@3x.png',
                            price: 190000,
                        },
                        {
                            image:
                                'https://rift-prod.s3.ap-south-1.amazonaws.com/landing-page/44@3x.png',
                            price: 230000,
                        },
                        {
                            image:
                                'https://rift-prod.s3.ap-south-1.amazonaws.com/landing-page/24@3x.png',
                            price: 230000,
                        },
                        {
                            image:
                                'https://rift-prod.s3.ap-south-1.amazonaws.com/landing-page/Northen_Heights_3BHK_Tropical_Bedroom3_Cam01@3x.png',
                            price: 130000,
                        },
                        {
                            image:
                                'https://rift-prod.s3.ap-south-1.amazonaws.com/landing-page/1@3x.png',

                            price: 110000,
                        },
                    ],
                },
                past_projects: {
                    title: 'Past Projects done by Siloho',
                    eventImages: [
                        {
                            name: 'Navi Mumbai',
                            video:
                                'https://rift-prod.s3.ap-south-1.amazonaws.com/static/landing-page/videos/top-design-1.mp4',
                        },
                        {
                            name: 'Casa Melhor, Thane',
                            video:
                                'https://rift-prod.s3.ap-south-1.amazonaws.com/static/landing-page/videos/top-design-2.mp4',
                        },
                        {
                            name: 'Tata Housing',
                            video:
                                'https://rift-prod.s3.ap-south-1.amazonaws.com/static/landing-page/videos/top-design-3.mp4',
                        },
                        {
                            name: 'Gera River of Joy 2 BHK',
                            video:
                                'https://rift-prod.s3.ap-south-1.amazonaws.com/static/landing-page/videos/top-design-4.mp4',
                        },
                    ],
                    vt_title: 'Northern Heights Apartments Virtual Tour',
                },
            },
            testimonials: [
                {
                    name: 'D K Gupta',
                    bg_image:
                        'https://rift-prod.s3.ap-south-1.amazonaws.com/static/landing-page/images/client-house-2.png',
                    city_name: 'Ranchi',
                    msg:
                        'I wanted a home which captured the personalities of my wife and me, without sacrificing functionality. Also, since both of us have very hectic work schedules, we wanted someone to take ownership of the whole process and keep us updated at the same time.The Siloho team were a lifesaver and a magician at the same time. They captured our requirements in detail and designed a home which was even better than what I could have imagined. More importantly, their management of the whole process was almost flawless and they did not overtake . I will recommend their services to anyone who wants a beautiful home.',
                    user_image:
                        'https://rift-prod.s3.ap-south-1.amazonaws.com/static/landing-page/images/client-img-2.png',
                    before_image:
                        'https://rift-prod.s3.amazonaws.com/photos/2022/02/21/before1.JPG',
                    after_image:
                        'https://rift-prod.s3.amazonaws.com/photos/2022/02/21/after1.JPG',
                },
                {
                    name: 'Deepak Daga',
                    bg_image:
                        'https://rift-prod.s3.ap-south-1.amazonaws.com/static/landing-page/images/client-house-1.png',
                    city_name: 'Delhi',
                    msg:
                        'Our home interior was handled very professionally by the Siloho team. Designer Unny Varghese helped us a lot in our selection of material which fits our needs and budget. Time to time he was visiting and checking that the tasks were going on as planned, and were completed on time. Extremely professional behavior with regular updates - A job well done. Operations were handled by Mr Ajay and under his guidance workers done their job really well. My kitchen & room is looking really stunning now. ',
                    user_image:
                        'https://rift-prod.s3.ap-south-1.amazonaws.com/static/landing-page/images/client-img-1.JPG',
                    before_image:
                        'https://rift-prod.s3.amazonaws.com/photos/2022/02/21/before2.JPG',
                    after_image:
                        'https://rift-prod.s3.amazonaws.com/photos/2022/02/21/after2.JPG',
                },
                {
                    name: 'Apeksha Singh',
                    bg_image:
                        'https://rift-prod.s3.ap-south-1.amazonaws.com/static/landing-page/images/client-house-3.png',
                    city_name: 'Lucknow',
                    msg:
                        'Siloho was recommended to me by a friend who had used their services for the furnishing of their vacation home in Goa. I was looking for someone to add a fresh and modern look to our ancestral home in Nainital, and I must say that they did not disappoint. There creative suggestions were super helpful, and they helped me to identify and procure the perfect products which would add a dash of modern functionality without losing  the old world charm. Kudos to Shreeya and her team!',
                    user_image:
                        'https://rift-prod.s3.ap-south-1.amazonaws.com/static/landing-page/images/client-img-3.png',
                    before_image:
                        'https://rift-prod.s3.amazonaws.com/photos/2022/02/21/before3.JPG',
                    after_image:
                        'https://rift-prod.s3.amazonaws.com/photos/2022/02/21/after3.JPG',
                },
            ],
            url:
                window.location.host === process.env.VUE_APP_DOMAIN_NAME
                    ? process.env.VUE_APP_QUIZ_BASE_URL_B2B
                    : process.env.VUE_APP_QUIZ_BASE_URL,
        }
    },
    metaInfo() {
        return {
            title: 'Northern Heights',
            meta: [
                // { name: 'description', content:  `${this.landingText.header_text.tag_line}`},
                // { property: 'og:title', content: `${this.landingText.header_text.event_name}`},
                // { property: 'og:type', content: 'website'},
                // // { property: 'og:url', content: 'https://uat-ecom.siloho.com/northen-heights/landing-page/'},
                // { property: 'og:image', content: 'https://uat-ecom.siloho.com/img/look-like.a2d9de58.png' }
            ],
        }
    },

    created() {
        this.registerStoreModule('HomePage', HomePageModule)
        this.registerStoreModule('ContactPage', ContactPageModule)
        // document.querySelector("meta[property='og:title']").content = `${this.landingText.header_text.event_name}`
        // document.querySelector("meta[property='og:description']").content = `${this.landingText.header_text.tag_line}`
        // // document.querySelector("meta[property='og:url']").content = 'https://uat-ecom.siloho.com/northen-heights/landing-page/'
        // document.querySelector("meta[property='og:image']").content = 'https://uat-ecom.siloho.com/img/look-like.a2d9de58.png'
    },
    computed: {
        ...mapState({
            testimonialList: state => state.HomePage.testimonialList,
            windowWidth: state => state.AppModule.windowWidth,
        }),
    },
    methods: {
        // To start the style quiz
        startQuiz() {
            window.open(`${this.url}?startQuiz=true&fq=true`, '_self')
        },

        // Display Virtual Tour
        showVt() {
            window.open(`https://tours360.siloho.com/tours/bIKHK8DQE`)
        },
    },
}
</script>
<style lang="scss" scoped>
@import './NorthenHeights.scss';
</style>
